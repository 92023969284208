import React from 'react';
import hrefs from '../etc/urls_partenaires.json'

function PartnersGallery() {
  // Utilise require.context pour importer dynamiquement les images à partir du répertoire "images"
  function importAll(r) {
    return r.keys().map(fileName => ({
      fileName,
      fileContent: r(fileName)
    }));
  }
  const images = importAll(require.context(process.env.PUBLIC_URL + '/public/images/partenaires/2024/', false, /\.(png|jpe?g|svg)$/));

  images.sort((a, b) => {
    const nameA = a.fileName.split('/').pop(); // Obtient le nom du fichier de l'image a
    const nameB = b.fileName.split('/').pop(); // Obtient le nom du fichier de l'image b
    return nameA.localeCompare(nameB); // Trie par ordre alphabétique
  });

  return (
    <div className="image-gallery">
      {images.map((image, index) => {
        // Vérifie si le nom de l'image contient l'un des mots-clés
        const hrefKeys = Object.keys(hrefs);
        let selectedKeyword = '';
        let href = '';
        let town = '';
        // Vérifie si le nom de l'image contient l'un des mots-clés
        const hasKeyword = hrefKeys.some(keyword => {
          if (image.fileName.includes(keyword)) {
            if (keyword.indexOf("_") > -1) {
              town = keyword.substring(keyword.indexOf("_") + 1)
            }
            // console.log(keyword, keyword.indexOf("_"))
            selectedKeyword = keyword;
            href = hrefs[selectedKeyword];
            return true;
          }
          return false;
        });

        // Crée un lien si l'image contient l'un des mots-clés
        if (hasKeyword) {
          if (town) {
            return (
              <div class="partner">
                <a target="_blank" rel="noreferrer" key={index} href={href} className='ville_partenaire'>
                  <img src={image.fileContent} alt="" className='partenaire' />
                </a>
                <span className="town">{town}</span>
              </div>
            );
          } else {
            return (
              <div class="partner">
                <a target="_blank" rel="noreferrer" key={index} href={href}>
                  <img src={image.fileContent} alt="" className='partenaire' />
                </a>
              </div>
            )
          } 
        } 
        // else {
        //   return <img key={index} src={image.fileContent} alt="" className='partenaire' />;
        // }
      })}
    </div>
  );
}

export default PartnersGallery;
