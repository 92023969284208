import React from 'react';
import cddp from "./img/pictogrammes/cdpp.svg"
import cdpp_blanc from "./img/pictogrammes/cdpp_blanc.svg"
import condor from "./img/rhucondor.jpg"
import faireundon from "./img/pictogrammes/faireundon.svg"


function Titre({ libelle, color, image }) {

    if (image === "cddp") {
        return (
            <div class="row rowtitle">
                <div className={`title col card-prog ${color}`}>
                    <h1>
                        <img className="logocddp" src={cdpp_blanc} alt="" />{libelle}
                    </h1>
                </div>
            </div>
        )
    } else {
        return (
            <div class="row rowtitle">
                <div className={`title col card-prog ${color}`}>
                    <h1>
                        <img className="logocddp" src={condor} alt="" />{libelle}
                    </h1>
                </div>
            </div>
        )
    }
}

function FaireunDon() {
    return (
        <button class="btn discret">
            <a target="_blank" href="https://www.helloasso.com/associations/la-course-du-petit-prince-la-cdpp/formulaires/1">
                <img src={faireundon} width="100px"></img>
            </a>
        </button>
    )

}

function LienPhotos({titre, link}) {
    return (
        <div className="col card-prog">
            <h2>
                <a target="_blank" rel="noreferrer" href={link}>{titre}</a>
            </h2>
        </div>
    )

}

function LienVideo({image, link}) {
    return (
        <div className="col card-prog">
            <h2>
                <a target="_blank" rel="noreferrer" href={link}><img className="img-fluid mx-auto" src={image} alt='' width="200px" /></a>
            </h2>
        </div>
    )

}


export { Titre, FaireunDon, LienPhotos, LienVideo };
