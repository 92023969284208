import React from 'react';
import YouTube from 'react-youtube';

function VideoPlayer({ videoId }) {
  // Options pour le lecteur YouTube (voir la documentation pour plus d'options)
  const opts = {
    height: '315',
    width: '560',
    playerVars: {
      // Paramètres supplémentaires, si nécessaire
      autoplay: 0,
    },
  };

  return (
    <div className="video-container">
      <YouTube videoId={videoId} opts={opts} />
    </div>
  );
}

export default VideoPlayer;
