import React from "react"
// import Info from '../Info/InfoRecherche.js'
import banniererecherche from '../img/banniererecherche.png'
import './Recherche.css'
import { FaireunDon } from "../Fonctions.js"
import { Titre } from "../Fonctions"

export default function Recherche() {

    const articles = [
        { "titre": "", "link": "https://www.linkedin.com/posts/rhu-condor_2023-retrospective-activity-7152982347515138048-XeYz?utm_source=share&utm_medium=member_desktop" },
        { "titre": "", "link": "https://www.linkedin.com/posts/rhu-condor_misabrs2023-sarcomacare-immunotherapy-activity-7112103584472674304-LOKe?utm_source=share&utm_medium=member_desktop" },
        { "titre": "", "link": "https://www.linkedin.com/posts/rhu-condor_cdpp-rhucondor-sarcoma-activity-7064855544066453504-coEg?utm_source=share&utm_medium=member_desktop" },
        { "titre": "", "link": "https://www.linkedin.com/posts/rhu-condor_rencontres-sud-ouest-tv7-tout-sur-les-activity-7084553561774149633-OJ4c?utm_source=share&utm_medium=member_desktop" },
        { "titre": "", "link": "https://www.linkedin.com/posts/rhu-condor_new-immunotherapy-strategies-for-patients-activity-7094947915902726144-28Km?utm_source=share&utm_medium=member_desktop" },
        { "titre": "", "link": "https://www.linkedin.com/posts/rhu-condor_clarivate-highlycitedresearchers-researchexcellence-activity-7132657878544326657-fnrw?utm_source=share&utm_medium=member_desktop" }
    ];

    const newsletter = [
        { "titre": "NEWSLETTER #2", "link": "https://sh1.sendinblue.com/vpg1o470lpfe.html?t=1683039928" },
        { "titre": "NEWSLETTER #3", "link": "https://sh1.sendinblue.com/vxvgc470lpfe.html?t=1701078687" },
    ];

    function get_url_trad(url) {
        const parsedURL = new URL(url);
        const { protocol, hostname, pathname, search, hash } = parsedURL;
        return protocol + "//" + hostname.replace(/\./gi, "-") + ".translate.goog" + pathname + search + "&_x_tr_sl=auto&_x_tr_tl=fr&_x_tr_hl=fr&_x_tr_pto=nui"
    }

    const listarticles = articles.map((article, index) => {
        const urlTrad = get_url_trad(article.link)
        return (
            <div class="col-6">
            <div class="card-prog dark">
                <div class="articles">
                    {/* <div class="iframewrap">
                        <iframe src={article.link} title={index}></iframe>
                    </div> */}

                            <h3>Article {index + 1}</h3>
                            {/* <button key={index} src={urlTrad} className="btn btn-primary">
                                <a target="_blank" rel="noreferrer" href={urlTrad}>Traduit</a>
                            </button> */}
                            <button key={index} src={article.link} className="btn btn-info">
                                <a target="_blank" rel="noreferrer" href={article.link}>Original</a>
                            </button>
                </div>
            </div>
            </div>

        );
    });

    // <div className="col card-prog transparent"></div>
    const listnewsletter = newsletter.map((article, index) => {
        const urlTrad = get_url_trad(article.link);
        return (
            // <div class="col-6 col-xs-6">
            <div class="col-6 col-xs-12">
            <div class="card-prog dark">
                <div class="newsletter">
                    <h3>News Letter {index + 1}</h3>
                    {/* <div class="iframewrap">
                        <iframe src={article.link} title={index}></iframe>
                    </div> */}
                    {/* <button key={index} src={urlTrad} className="btn btn-primary">
                        <a target="_blank" rel="noreferrer" href={urlTrad}>Traduit</a>
                    </button> */}
                    <button key={index} src={article.link} className="btn btn-info">
                        <a target="_blank" rel="noreferrer" href={article.link}>Original</a>
                    </button>
                </div>
            </div>
         </div>
        )
    }
    );

    return (
        <div id="Recherches" className="container-fluid">
            <div className="Recherche">
                <div className="row bandeau">
                    <img id="banniererecherche" src={banniererecherche} alt="" />
                    <FaireunDon/>
                </div>
                <div className="container">
                    <Titre libelle="Les News Letters du RHU CONDOR" color="yellow" image="condor" />
                    <div class="row">
                        {listnewsletter}
                    </div>
                    <Titre libelle="Le programme RHU CONDOR" color="yellow" image="condor" />
                    <div class="row">
                        {listarticles}
                    </div>
                </div>
            </div>
        </div>
    )
}