import React from "react"
import './Resultats.css'
import { Titre } from "../Fonctions"
import { LienPhotos, LienVideo } from "../Fonctions"
import youtube01 from '../img/2024/youtube01.png'
import youtube02 from '../img/2024/youtube02.png'


export default function Resultats() {

    return (
        <div id="Resultats">
            {/* PRESENTATION */}
            <div className="container-fluid" id='home'>
                <div className="row bandeau">
                    <div className="col-sm-12 col-xs-12">
                        <h1>L'édition 2024 est terminée.</h1>
                        <h2>Un grand merci à tous et à toutes.</h2>
                        <h2>En attendant l'édition 2025...</h2>
                    </div>
                </div>
            </div>
            <div className="container">
                <Titre libelle="Les Résultats" color="blue" image="cddp" />
                <div className="row">
                    <div className="col card-prog dark">

                        <h2>
                            <a target="_blank" rel="noreferrer" href="https://www.klikego.com/resultats/la-course-du-petit-prince-2024/1644528897784-3">Les enfants, le 15 et le 7 </a>
                        </h2>

                    </div>

                </div>
                
                <Titre libelle="Une vidéo" color="blue" image="cddp" />
                <div className="row">
                    <LienVideo image={youtube02} link="https://youtu.be/cSDko4yy48g" />
                    <LienVideo image={youtube01} link="https://youtu.be/3mF0j-JFLbI?si=znuXmeX-22b9nmYs" />
                </div>

                <Titre libelle="Les Photos" color="blue" image="cddp" />

                <div className="row">
                    <LienPhotos titre="Le village #1" link="https://photos.app.goo.gl/7geQQHqNHrBXFGVZ9" />
                    <LienPhotos titre="Le village #2" link="https://photos.app.goo.gl/3c6AQKhAAvgqxcuF7" />
                    <LienPhotos titre="Le village #3" link="https://photos.app.goo.gl/efRHtpotPe3rRXkc8" />
                </div>

                <div className="row">
                    <LienPhotos titre="Courses des enfants" link="https://photos.app.goo.gl/ADL7N67MVRdzhD4Q6" />
                </div>

                <div className="row">
                    <LienPhotos titre="Trails #1" link="https://photos.app.goo.gl/Km3LZqV59pGBNQrXA" />
                    <LienPhotos titre="Trails #2" link="https://photos.app.goo.gl/hgNkewAcmtSaftnb6" />
                    <LienPhotos titre="Arrivée  des Trails" link="https://photos.app.goo.gl/tviXrMKxSfcyQ8Cy9" />
                </div>

                <div className="row">
                    <LienPhotos titre="Marcheurs #1" link="https://photos.app.goo.gl/jo44fLQ1ACtD1VzXA" />
                    <LienPhotos titre="Marcheurs #2" link="https://photos.app.goo.gl/27zJtfdqLc8CJ3wTA" />
                </div>

                <div className="row">
                    <LienPhotos titre="En forêt" link="https://photos.app.goo.gl/s1GALN3MEyKhNR2g7" />
                    <LienPhotos titre="Le Concert" link="https://photos.app.goo.gl/2AWyisR163RTgCNWA" />
                </div>

            </div>
        </div >
    )
}